.question-textarea {
    resize: none;
}

form.ng-submitted {
    input.ng-invalid:not(:focus) {
        border-color: var(--red);

        & + .input-group-append {
            .input-group-text {
                border-color: var(--red);
            }
        }
    }
}

.delete-input-search {
    cursor: pointer;

    &:hover {
        color: var(--default);
    }
}

.valid-feedback,
.warning-feedback,
.invalid-feedback {
    position: absolute;
    display: block;
}

.select-simplified-mode {
    .select-container {
        .select {
            max-height: 14.5rem !important;
        }
    }
}
