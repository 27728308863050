.card-body {
    .card-body-container {
        padding: 1.5rem 2rem;
        margin-bottom: 0;
        background-color: #fff;

        &:not(:first-child) {
            border-top: 1px solid rgba(0, 0, 0, 0.05);
        }

        .container-section {
            padding: 1.5rem 2rem;

            &:not(:first-child) {
                border-left: 1px solid rgba(0, 0, 0, 0.05);
            }
        }
    }

    .card-body-chart-container {
        padding: 0;
        margin-bottom: 0;
        background-color: #fff;

        &:not(:first-child) {
            border-left: 1px solid rgba(0, 0, 0, 0.05);
        }
        .chart-header-container {
            padding: 1.5rem 2rem;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
        .chart-container {
            padding: 1.5rem 2rem;
        }

        .chart-agent-advice-container {
            padding: 2rem;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
        }
    }
}

.empty-widget {
    height: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.series-card {
    .card-body {
        padding: 1.2rem 2.1rem;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
    }
}

.target-card {
    height: 100%;
    width: 100%;
    display: flex;

    .card {
        border-bottom: 4px solid #e9ecef;

        &.active {
            border-bottom-color: theme-color("primary");
        }
    }

    .card-body {
        min-height: 30rem;
    }

    .detail-chart {
        max-height: 370px;
    }
}

.flipping-card-container {
    -webkit-perspective: 100rem;
    perspective: 100rem;

    .flipping-card {
        position: relative;
        width: 100%;
        height: 22.7rem;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: transform 1s;
        -ms-transform: transform 1s;
        transform: transform 1s;
    }
    .card-side {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .card-side-front {
        transform: rotateY(0deg);
    }
    .card-side-back {
        transform: rotateY(-180deg);
    }
}

.filters-card {
    padding: 0.7rem 1rem;
    padding-bottom: 0;
    max-height: 12.7rem;
    overflow-y: auto;
    overflow-x: hidden;

    &.full-height {
        min-height: 12.7rem;
    }

    &.min-height {
        min-height: 7rem;
    }

    .filter-badge {
        margin-bottom: 0.7rem;
        line-height: 1;

        &:not(:last-child) {
            margin-right: 1.4rem;
        }
    }

    &.disabled {
        background-color: $input-disabled-bg;
        border: $input-border-width solid $input-border-color;
        border-radius: $input-border-radius;
        color: $input-placeholder-color;
        padding: 1rem 2rem;
        line-height: 1;
    }
}
