.btn-group > .btn.active{
    z-index: 0;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: #e9ecef;
    border-color: #e9ecef;
    font-weight: 500;
}

label.btn-secondary {
    font-weight: 500;
}

.btn-link {
    font-weight: normal !important;
    white-space: nowrap;
    &:not(:disabled){
        cursor: pointer;
    }
}

.btn-link.primary.active {
    color: white !important;
    background-color: $primary;
}

.btn-link.active {
    background-color: $gray-200;
}

.btn-flat-light {
    border: 0;
    width: fit-content;
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;

    color: #8d9cae;
    background-color: $white;

    &:disabled {
        background-color: #eaecef;
        opacity: 1;
    }

    &:hover {
        box-shadow: none !important;
        transform: none !important;
    }
}

.btn-group-rounded {
    .btn.btn-light-primary {
        border-radius: 25rem !important;
        width: 11.6rem;

        &:not(.active) {
            background-color: white;
            color: $gray-600;
        }
    }
}

.btn-light-primary.disabled,
.btn-light-primary:disabled {
    border: none !important;
}

.btn-dropdown {
    padding: 0.4rem 0.8rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}
