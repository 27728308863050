.date-reference-container {
    min-width: 40rem;
}

.variables-container {
    height: 34rem;
    overflow-y: auto;
}

.sensitivity-variables {
    height: 24.5rem;
    overflow-y: auto;
}

.notifications-container {
    max-height: 30rem;
    overflow-y: auto;
}

.scenarios-container {
    max-height: 28.8rem;
    overflow-y: auto;
}

.selected-variables-container {
    max-height: 41rem;
    overflow-y: auto;

    &.placeholder {
        max-height: 46.7rem;
    }
}

.kpi-card-detail-container {
    max-height: 14rem;
    display: block;
    overflow-y: auto;
}

.strategy-variables-container {
    font-size: 1.4rem;
    margin: 1.6rem 0;
    overflow-y: auto;
    max-height: 40rem;

    > div:nth-child(even) {
        background-color: $secondary;
    }
}

.step-choice-container {
    cursor: pointer;
    word-break: break-word;
    position: relative;
    padding: 2rem;
    border-radius: 0.8rem;
    color: $default;
    height: 100%;
    min-height: 24rem;
    border: 1px solid $gray-400;

    &:hover {
        background-color: map-get($shapes-primary-colors, primary-10);
    }

    &.selected {
        cursor: auto;
        border: 1px solid $primary;
        background-color: map-get($shapes-primary-colors, primary-10);
    }

    &.disabled {
        cursor: auto;
        border: 1px dashed $gray-400;
        background-color: $gray-100;
    }
}

.template-card {
    @extend .step-choice-container;
    cursor: auto;

    &.new-item {
        border: 1px dashed $gray-400;
    }
}

.step-variables-choice-container {
    height: 56rem;

    .card-body {
        padding: 0;
        display: flex;
        flex-direction: column;

        .strategy-variables-container {
            overflow-y: auto;
            margin: 0;

            .empty-search {
                color: $text-muted;
                background-color: #fff;
                font-size: 1.4rem;
                margin: 1.6rem;
            }
        }
    }
    .variables-total {
        background-color: #fff;
        font-size: 1.4rem;
        padding: 1.6rem;
    }
}

.recap-step-container {
    padding: 2rem;
    border-top: 1px solid $card-border-color;

    &.strategy-variables-container {
        border: none;
        padding: 0;
        margin: 0;
        max-height: none;

        .variables-group {
            padding: 0.8rem 1.6rem;
            padding-left: 6rem;

            .category-title {
                padding: 0.8rem 0;
                color: $primary;
            }
            .variable-name {
                padding: 0.8rem 0;
                color: $default;
            }
        }
    }
}

.scenario-updates-table-container {
    position: relative;
    padding: 0;
}

.scenario-advanced-updates-operation-container {
    display: flex;
    align-items: center;

    .base-values-operation {
        margin-left: 15rem;
    }

    .base-values-operation,
    .delta-operation {
        padding: 0.8rem 1.6rem;
        min-width: 19rem;
        width: 19rem;
    }
}

.benchmark-performance-container {
    height: 24rem;

    .performance-gauge-chart {
        min-height: 25rem;
        overflow: hidden;
    }
    
    .performance-gauge-container {
        height: 9em;
    }
}

.benchmark-chart-container {
    padding: 0;
    height: 24rem;
    padding-top: 1.2rem;
    
    .benchmark-chart{
        height: 19rem;
    }
}
