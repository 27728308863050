@import 'font-awesome/font-awesome';
@import 'vedrai-suit/vedrai-suit';
@import 'vedrai-ui/vedrai-ui';

// TODO to kit
.navbar-vertical .navbar-nav {
    .nav-link > i,
    fa-icon {
        width: $navbar-icon-min-width;
    }
}

.toast-success {
    cursor: default !important;
}
