.table {
    thead th {
        padding-top: $table-head-spacer-y;
        padding-bottom: $table-head-spacer-y;
        font-size: $table-head-font-size;
        text-transform: $table-head-text-transform;
        letter-spacing: $table-head-letter-spacing;
        border-bottom: $table-border-width solid $table-border-color;
    }

    th {
        font-weight: $table-head-font-weight;
    }

    td {
        .progress {
            height: 3px;
            width: 120px;
            margin: 0;
        }
    }

    td,
    th {
        font-size: $table-body-font-size;
        white-space: nowrap;
    }


    // Vetical align table content

    &.align-items-center {
        td,
        th {
            vertical-align: middle;
        }
    }


   // Styles for dark table

   .thead-dark {
        th {
            background-color: $table-dark-head-bg;
            color: $table-dark-head-color;
        }
    }


    // Styles for light table

    .thead-light {
        th {
            background-color: $table-head-bg;
            color: $table-head-color;
        }
    }
}

.table {
    font-size: 1.6rem;
    margin-bottom: 0;

    th,
    td {
        vertical-align: middle;
    }

    td {
        min-height: 6rem;
    }

    td>span {
        max-width: 39rem;
        display: block;
    }

    th {

        .header-column {
            display: flex;

            &:last-child {
                margin-right: 3px;
            }

            &:first-child {
                margin-left: 3px;
            }
        }

        .select-all {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            .form-check {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .sortable:hover {
            text-decoration: underline;
            cursor: pointer;
        }

        .sortable-icon {
            position: relative;
            width: 3rem;
        }
    }

}

.table td>span {
    white-space: normal;
}

.table-loading {
    min-height: 40rem;
}

.best-scenario-row td {
    background-color: #edfaf1 !important;
}

.sticky-header {
    &::-webkit-scrollbar-track {
        margin-top: calc(var(--data-scrollbar-margin-top) * 1px);
    }

    th {
        position: sticky;
        top: 0px;
        color: $table-head-color;
        background-color: $table-head-bg !important;

        border-top: none !important;
        border-bottom: none !important;
        z-index: 4;

        div.header-column {
            margin: 0 !important;
        }
    }

    th:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid $table-border-color;
        border-top: 1px solid $table-border-color;
        z-index: -1;
    }
}

.strategy-reports-table-header {
    @extend .sticky-header;

    th:after {
        border-top: none;
    }
}

.scenario-variable-values-header th,
.cashflow-table-header th,
.indexes-table-header th {

    position: sticky;
    top: 0;
    color: $table-head-color;
    border-top: none !important;
    border-bottom: none !important;
    z-index: 4;

    .header-column {
        &:last-child {
            margin-right: 0px;
        }
    }

    div {
        justify-content: right;
    }

    &:first-child {
        div {
            justify-content: left;
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid $table-border-color;
        border-top: 1px solid $table-border-color;
        z-index: -1;
    }
}

.scenario-table-header {
    @extend .scenario-variable-values-header;

    .table-responsive {
        min-height: 20rem !important;
    }

    th {
        padding: 1.2rem 1.6rem;
        background-color: $table-head-bg !important;
    }

    th:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid $table-border-color;
        border-top: 1px solid $table-border-color;
        z-index: -1;
    }
}


.indexes-table-header th:not(.bg-secondary) {
    background-color: white !important;
}


.scenarios-table-loading {
    min-height: 20rem !important;
}

.scenarios-table-container .sticky-table {
    max-height: 40rem;
}

.table-container {
    position: relative;
}

.strategies-table,
.scenarios-table,
.indexes-table,
.scenarios-updates-table {

    td,
    th {
        border-left: 0;
        border-right: 0;
    }

    th {
        padding: 1.2rem 1.6rem !important;
        font-size: 1.2rem !important;
    }

    td {
        padding: 0.8rem 1.6rem !important;
        font-size: 1.4rem !important;

        >span {
            white-space: nowrap;
        }

        &.scenario-name-column {
            min-width: 25rem;
            max-width: 25rem;
        }

        &.index-name-column {
            min-width: 25rem;
            max-width: 25rem;

            span {
                width: inherit;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.strategy-name-column {
            min-width: 25rem;
            max-width: 25rem;

            a {
                display: block;
                width: inherit;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.actions-column {
            width: 9rem;
            min-width: 9rem;
            max-width: 9rem;
        }
    }

    tbody {
        tr {
            &:first-child {

                td,
                th {
                    border-top: 0;
                }
            }

            &:last-child {

                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}

.scenarios-updates-table {
    .table-responsive {
        max-height: 32.8rem !important;
    }

    table th {
        padding: 0.8rem 1.6rem !important;

        .header-column {
            justify-content: flex-end;
            margin: 0 !important;
        }

        .sort-icons {
            display: none;
        }

        &:not(:last-child) {
            border-right: 1px solid $gray-400;
        }
    }

    td {
        max-height: 3.4rem;
        text-align: right;

        &:not(.input-column) {
            border: 1px solid $gray-400 !important;
        }

        &.base-values-column {
            background-color: $input-focus-muted-bg;
        }

        &.date-column {
            min-width: 15rem;
            width: 15rem;
            font-weight: 600;
        }

        &.base-values-column,
        &.delta-column {
            min-width: 19rem;
            width: 19rem;
        }

        &.input-column {
            padding: 0 !important;
            position: relative;
            top: 1px;
            border: 0 !important;

            input {
                width: 100%;
                height: 100%;

                text-align: right;
                padding: 1rem;
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid $gray-400;
                transition: none;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                outline: 0;

                &.ng-dirty:not(.resetted) {
                    font-weight: 600;
                }

                &:focus,
                &:focus-visible {
                    border: 2px solid $primary !important;
                    background-color: $secondary;
                    z-index: 2;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &[type="number"] {
                    -moz-appearance: textfield;
                    /* Firefox */
                }
            }
        }
    }
}

.center-header-column .header-column {
    justify-content: center !important;
}

.scenario-variable-values-table,
.cashflow-table {

    th,
    td {
        border: 1px solid $table-border-color;
    }

    tr {

        th:first-child,
        td:first-child {
            border-left: none;
            width: 50%;
        }

        th:last-child,
        td:last-child {
            border-right: none;
        }

        th {
            background-color: #fff;
            font-size: 1.2rem !important;
            padding: 1.2rem 1.6rem;
        }

        td {
            min-height: auto;
            padding: 0.8rem 1.6rem;
            font-size: 1.1rem !important;

            >span {
                white-space: nowrap;
            }
        }
    }
}

.strategy-reports-table {
    th {
        font-size: 1.2rem !important;
        padding: 1.2rem 1.6rem !important;
    }

    td {
        min-height: auto;
        padding: 0.8rem 1.6rem !important;
        font-size: 1.4rem !important;

        .strategy-report-row-group {
            width: 32rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div:last-child {
                margin-left: auto;
            }
        }

        &.strategy-report-name-column {
            min-width: 25rem;
            max-width: 25rem;

            span {
                width: inherit;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.actions-column {
            width: 9rem;
            min-width: 9rem;
            max-width: 9rem;
        }

        >span {
            white-space: nowrap;
        }
    }
}

.scenario-variable-values-table tr {

    &.selected,
    &.selected td {
        background-color: $primary !important;
        color: white;

        td .btn-linked {
            color: white !important;
        }

        td .variable-values .variable-value,
        td .variable-values .variable-delta-percentage {
            color: white !important;
        }
    }

    th:not(:first-child):not(:last-child) {
        min-width: 23rem;
    }

    td {
        font-weight: 600;
        height: 3.2rem;

        .variable-values {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .variable-value {
                margin-right: 1.6rem;
                min-width: calc(13rem - 1.6rem);
                text-align: right;
                font-weight: 600;
                font-size: 1.3rem;
            }

            .variable-delta-percentage {
                position: relative;
                width: calc(7.7rem - 1.6rem);
                padding-left: 1.6rem;
                font-weight: 600;

                &::before {
                    content: "";
                    display: block;
                    height: 3.4rem;
                    width: 1px;
                    position: absolute;
                    left: 0;
                    top: -1rem;
                    background-color: #e9ecef;
                }
            }
        }

        .btn-linked {
            color: $gray-600;
        }
    }

    &.parent-row td {
        border-right: transparent;
        border-left: transparent;
        color: $gray-600;
    }

    th:first-child,
    td:first-child {
        width: calc(26rem - var(--data-level) * 2.4rem);
        border-right: 1px solid #e9ecef;

        .collapsable-cell {
            width: calc(26rem - var(--data-level) * 2.4rem);
        }

        .collapsable-cell>span {
            width: inherit;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    th:last-child,
    td:last-child {
        width: 3.4rem;
        padding: 0;
    }
}

.pending-update-cell {
    background-color: #f4faff;
}