.placeholder-image {
    &.xl {
        width: 35rem;
        height: 35rem;
    }

    &.lg {
        width: 30rem;
        height: 30rem;
    }

    &.md {
        width: 24rem;
        height: 24rem;
    }

    &.sm {
        width: 18rem;
        height: 18rem;
    }

    height: 30rem;
    width: 30rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    margin: 2rem 3rem 2rem 1rem;

    &.under-construction {
        background-image: url(/assets/images/pages/under-construction.svg);
    }

    &.dashboard-error {
        background-image: url(/assets/images/pages/dashboard-error.svg);
    }

    &.foresee {
        background-image: url(/assets/images/pages/foresee.svg);
    }

    &.question {
        background-image: url(/assets/images/pages/question.svg);
    }

    &.reports {
        background-image: url(/assets/images/pages/reports.svg);
    }

    &.scenario {
        background-image: url(/assets/images/pages/scenario.svg);
    }

    &.examine {
        background-image: url(/assets/images/pages/examine.svg);
    }

    &.compare {
        background-image: url(/assets/images/pages/compare.svg);
    }

    &.targets {
        background-image: url(/assets/images/pages/targets.svg);
    }

    &.create-question {
        background-image: url(/assets/images/pages/create-question.svg);
    }

    &.not-found-error{
        background-image: url(/assets/images/pages/not-found-error.svg);
    }
}

.report-image-preview {
    max-width: 100%;
    max-height: 45rem;
}

.james-image {
    max-width: 8rem;
    width: 100%;
    min-height: 12rem;
}
