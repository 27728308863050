.info-icon {
    font-size: 0.8rem;
    color: inherit;
    cursor: pointer;
}

.bell-counter {
    position: absolute;
    top: 3px;
    left: 0;
    font-size: 1rem;
    background-color: white;
    color: #555;
    font-weight: bold;
    border-radius: 30px;
    padding: 0 0.5rem;
}

.rotate {
    transform: rotate(180deg);
}

.sort-icons {
    margin-left: 0.3rem;
    margin-right: -0.3rem;
}

.recap-step-circle {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: $primary;

    color: #fff;
    font-size: 0.9rem;
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;
}
