.chart-tooltip-series {
    display: flex;
    justify-content: space-between;
}

.chart-tooltip-name {
    margin-right: 1.6rem;
}
.chart-tooltip-unit-measure {
    font-size: 1.4rem !important;
    margin-left: 0.4rem;
}

.chart-tooltip-label {
    margin-bottom: 0.8rem;
    
    &::first-letter {
        text-transform: capitalize;
    }
}

.chart-tooltip-line-marker {
    width: 10px;
    display: inline-block;
    height: 5px;
    border-top: 1px dashed black;
    margin-top: 5px;
    margin-right: 3px;
}

.chart-updates-marker {
    display: inline-block;
    margin-right: 4px;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: #BB63CF;
}

  