.skltn-card {
    flex: 1;
    background: #fff;
    height: 100%;

    &-avatar {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;

        &-sm {
            width: 30px;
            height: 30px;
        }

        &-md {
            width: 40px;
            height: 40px;
        }

        &-lg {
            width: 50px;
            height: 50px;
        }
    }

    &-paginator-number {
        height: 36px;
        width: 36px;
    }

    &-title {
        height: 24px;
        margin-bottom: 20px;
    }

    &-subtitle {
        height: 14px;
        margin: 4px 0px;
    }

    &-input {
        height: 34px;
    }

    &-agent-advice {
        height: 60px;
    }

    &-btn {
        height: 35px;
        width: 95px;

        &.btn-small {
            height: 28px;
        }
    }

    &-progressbar {
        height: 5px;
        width: 100%;
    }

    &-line {
        height: 12px;
        margin-bottom: 12px;

        &:last-child {
            width: 60%;
        }
    }

    &-title,
    &-subtitle,
    &-agent-advice,
    &-line {
        @for $i from 1 through 20 {
            &.w-#{$i * 5} {
                width: $i * 5%;
            }
        }
    }
}

.skltn-picture {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    &-right {
        flex-direction: row-reverse;
    }
    &-img {
        width: 70px;
        height: 50px;
    }
    &-caption {
        width: calc(100% - 90px);
    }
    &-line {
        height: 16px;
        margin-top: 16px;
        &:first-child {
            margin-top: 0;
        }
    }
}

.skltn-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    &-right {
        flex-direction: row-reverse;
    }
    &-checkbox {
        width: 2rem;
        height: 2rem;
    }
    &-caption {
        width: calc(100% - 3.2rem);
        margin-top: 0.2rem;
        padding-right: 1rem;
    }
    &-line {
        height: 1.4rem;
        margin-top: 1.8rem;
        &:first-child {
            margin-top: 0;
        }
    }
}

.skltn-toggle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    &-toggle {
        width: 4.8rem;
        height: 2.2rem;
    }
    &-caption {
        width: calc(100% - 3.2rem);
        max-width: 12rem;
        height: 2rem;
        margin-top: 0.2rem;
        padding-right: 4rem;
    }
    &-line {
        height: 1.8rem;
        margin-top: 1.8rem;
        &:first-child {
            margin-top: 0;
        }
    }
}

.skltn-line {
    height: 26px;
    margin-bottom: 16px;
}

@media (max-width: 640px) {
    .skltn-card {
        display: flex;
        flex-flow: row wrap;
        &-body {
            flex: auto;
        }
        &-avatar {
            width: 60px;
            height: 60px;
            margin: 0 20px 0 0;
        }
        &-title {
            height: 32px;
            margin-bottom: 20px;
        }
        &-line {
            height: 20px;
            margin-bottom: 20px;
        }
    }
}

.skltn-step-variables-choice-container {
    height: 56rem;
}

.skltn-step-choice-container,
.skltn-template-card {
    position: relative;
    padding: 2rem;
    border-radius: 0.8rem;
    color: $default;
    height: 100%;
    min-height: 24rem;
    border: 1px solid $gray-400;

    &.selected {
        border: 1px solid $primary;
        background-color: map-get($shapes-primary-colors, primary-10);
    }
}

.skltn-badge-step-icon {
    width: 4.4rem;
    height: 4.4rem;

    border-radius: 0.8rem;
}

.skltn-scenario-management-table-container {
    overflow-x: hidden;
    table {
        th:first-child,
        td:first-child {
            min-width: 250px;
        }

        th,
        td {
            min-width: 200px;
            margin: 10px 0;

            .skltn-table-cell {
                height: 13px;

                @for $i from 1 through 20 {
                    &.w-#{$i * 5} {
                        width: $i * 5%;
                    }
                }
            }
        }
    }
}
