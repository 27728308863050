// @property --num {
//     syntax: "<integer>";
//     initial-value: 0;
//     inherits: false;
// }

// div {
//     animation: counter 5s infinite alternate ease-in-out;
//     counter-reset: num var(--num);
//     font: 800 40px system-ui;
//     padding: 2rem;
// }
// div::after {
//     content: counter(num);
// }

// @keyframes counter {
//     from {
//         --num: 0;
//     }
//     to {
//         --num: 100;
//     }
// }

.row-group-key {
    font-size: 1.6rem;
    color: $primary;
    margin: auto 0;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: break-spaces;
    visibility: visible;
}

.text-table {
    color: $table-color;
}
