.round-light-badge {
    background-color: $secondary;
    border-radius: 0.8rem;
    padding: 1.6rem;

    span {
        color: #32335d;
    }
}

.small-round-light-badge {
    @extend .round-light-badge;

    background-color: map-get($shapes-primary-colors, primary-10);
    width: fit-content;
    padding: 0.4rem 0.8rem;
}

.lightest-primary-badge {
    @extend .small-round-light-badge;

    background-color: #f7f9fc;
    color: $primary;
    width: fit-content;
    padding: 0.4rem 0.8rem;
}

.info-color-badge {
    border-radius: 0.4rem;
    padding: 1.6rem 2rem;
    color: $info;
    background-color: rgba($info, 0.1);
    font-size: 1.4rem;
}

.info-light-badge{
    border-radius: 0.4rem;
    padding: 1.6rem 2rem;
    background-color: rgba($info, 0.1);

    color: $default;
    font-size: 1.4rem;

    .ng-fa-icon{
        color: $info;
    }
}

%badge-step {
    color: $primary;
    width: fit-content;
    background-color: map-get($shapes-primary-colors, primary-20);
}

.badge-step-icon {
    @extend %badge-step;
    background-color: map-get($shapes-primary-colors, primary-20);
    padding: 1.2rem;
    font-size: 2rem;
    border-radius: 0.8rem;
    display: flex;

    & svg {
        width: 2rem;
    }
}

.badge-step-item {
    @extend %badge-step;
    padding: 0.4rem 0.8rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    margin: 0.4rem 0;
}

.series-legend-badge {
    position: relative;

    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;

    line-height: 1;
    font-size: 1.2rem;
    font-weight: 600;

    color: $default;
    background: transparent;

    margin: 0.4rem;
    padding: 0.6rem 0.8rem;

    border: none;

    &:not(.disabled)::before {
        content: "";
        background-color: var(--data-color);
        opacity: 0.1;
        position: absolute;
        border-radius: 0.8rem;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 0;
    }

    & > span {
        z-index: 1;
    }

    &.disabled {
        color: #8d9cae;
        background-color: #eaecef;
        border-radius: 0.8rem;
    }

    &:focus-visible,
    &:focus {
        outline: none;
    }

    .series-color {
        height: 0.8rem;
        width: 0.8rem;
        margin-right: 0.8rem;
        border-radius: 50%;
        background-color: #e0e0e0;
    }
}
