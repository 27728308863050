.chart.chart-center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.target-chart,
.variable-chart {
    min-height: 40rem;
}

.preview-chart {
    min-height: 31rem;
}

.sentiment-chart {
    min-height: 22rem;
}

.exam-preview-chart {
    min-height: 20rem;
}

.scenario-variable-chart {
    min-height: 20rem;
}

.filters-chart {
    min-height: 50rem; // calc(100vh - 30rem);
    background: url(../../assets/images/pages/tree-background.png);
    overflow-x: hidden;
}

.cumulative-production-chart{
    min-height: 62rem;
}

.report-chart {
    min-height: 25rem;
}