.checklist-item-variable {
    cursor: grab;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
        font-size: 2rem;
        color: white;
        padding: 1rem;
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 50%;
        margin-right: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.good {
            color: white;
            background-color: theme-color("primary");
        }

        &.bad {
            color: white;
            background-color: theme-color("danger");
        }

        &.disabled {
            color: #333;
            background-color: lightgray;
        }
    }

    .checklist-info {
        flex: 1;
    }
}

.void-placeholder {
    height: 0;
    width: 0;
}